<template>
  <div class="bg-yellow-200" v-if="error">
    <div class="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between flex-wrap">
        <div class="w-0 flex-1 flex items-center">
          <span class="flex p-2 rounded-lg bg-yellow-900 text-white text-2xl">
            <fa :icon="['far', icon || 'bomb']" />
          </span>
          <p class="ml-3 font-medium text-yellow-900 truncate">
            <span class="md:hidden"> Error </span>
            <span class="hidden md:inline"> {{ message }} </span>
          </p>
        </div>
        <!-- <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
          <div class="rounded-md shadow-sm">
            <a
              class="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-yellow-600 bg-white hover:text-yellow-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
            >
              Learn more
            </a>
          </div>
        </div> -->
        <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3" v-if="closable">
          <button
            type="button"
            class="-mr-1 flex p-2 rounded-md hover:bg-yellow-500 focus:outline-none focus:bg-yellow-500 sm:-mr-2 transition ease-in-out duration-150"
            @click="$emit('close')"
          >
            <fa :icon="['far', 'times']" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SqrErrorBanner',
  props: {
    icon: String,
    error: { type: [Error, Object, String] },
    closable: Boolean,
  },
  computed: {
    message() {
      if (typeof this.error === 'string') {
        return this.error;
      } else {
        return this.error?.message || this.error?.toString();
      }
    },
  },
};
</script>
