<template>
  <time :datetime="datetime">{{ datetime }}</time>
</template>

<script>
export default {
  name: 'SqrDatetime',
  props: {
    value: { type: [Object, String] },
    missing: { type: String, default: '' },
  },
  computed: {
    datetime() {
      const value = this.value?.datetime ?? this.value;
      if (value) {
        return new Date(Date.parse(value)).toLocaleString();
      }
      return this.missing;
    },
  },
};
</script>
