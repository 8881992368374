<template>
  <main class="bg-gray-200 min-h-screen">
    <div class="max-w-3xl mx-auto sm:px-6 lg:px-8">
      <section class="py-8">
        <h1 class="text-2xl font-semibold p-4">
          {{ $t('integrations_title') }}
        </h1>
        <sqr-progress v-show="loading" />
        <sqr-error-banner :error="error" />
        <div class="bg-white shadow overflow-hidden sm:rounded-md">
          <ul>
            <li v-for="(int, index) in integrations" :key="int.id">
              <router-link
                :to="{
                  name: 'company-integration-centaur',
                  params: { cid, iid: int.id },
                }"
                class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
                :class="{ 'border-t border-gray-200': index > 0 }"
              >
                <div class="flex items-center px-4 py-4 sm:px-6">
                  <div class="min-w-0 flex-1 flex items-center">
                    <div
                      class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4"
                    >
                      <div>
                        <div
                          class="text-sm leading-5 font-medium text-blue-600 truncate"
                        >
                          {{ int.name }}
                        </div>
                      </div>
                      <div class="hidden md:block">
                        <div>
                          <div class="text-sm leading-5 text-gray-900">
                            {{ $t('integrations_last_update') }}
                            <sqr-datetime :value="int.updated" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <svg
                      class="h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SqrProgress from '@/sqrd-ui/SqrProgress';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';
import SqrDatetime from '@/sqrd-ui/SqrDatetime';
import company from './company';
export default {
  name: 'CompanyIntegrations',
  mixins: [company],
  components: { SqrProgress, SqrErrorBanner, SqrDatetime },
  computed: {
    ...mapState('integrations', { integrations: 'docs' }),
    ...mapState('integrations', ['loading', 'error']),
  },
  mounted() {
    const path = `companies/${this.cid}/integrations`;
    this.sub({ path });
  },
  methods: {
    ...mapActions('integrations', ['sub']),
  },
};
</script>
