<template>
  <div>
    <label
      v-if="label"
      class="block text-sm font-medium leading-5 text-gray-700"
      >{{ label }}</label
    >

    <div
      class="w-full bg-gray-200 h-2 relative overflow-hidden"
      :class="[{ 'rounded-full': rounded }, { indeterminate: indeterminate }]"
    >
      <div
        class="h-full progressbar"
        :class="[
          `bg-${color}-500`,
          { 'absolute top-0': indeterminate },
          { 'rounded-full': rounded },
        ]"
        role="progressbar"
        :style="{ width: percentage }"
        :aria-valuenow="percentage"
        :aria-valuemin="min"
        :aria-valuemax="max"
      >
        <span class="flex items-center h-full">
          <slot></slot>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { isNil } from 'ramda';
export default {
  inheritAttrs: false,
  props: {
    label: String,
    color: { type: String, default: 'blue' },
    value: { type: Number },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    rounded: { type: Boolean, default: false },
  },
  computed: {
    indeterminate() {
      return isNil(this.value);
    },
    percentage() {
      if (this.indeterminate) return undefined;
      const percent = (100.0 * (this.value - this.min)) / (this.max - this.min);
      return `${percent}%`;
    },
  },
};
</script>
<style scoped>
@keyframes progress-indeterminate {
  0% {
    width: 30%;
    left: -40%;
  }
  60% {
    left: 100%;
    width: 100%;
  }
  to {
    left: 100%;
    width: 0;
  }
}
.progressbar {
  transition: width 0.25s ease;
}
.indeterminate .progressbar {
  animation: progress-indeterminate 1.4s ease infinite;
}
</style>
